import { TabsContent } from '@/components/ui/tabs';
import { ScriptDetailsTab } from '../editor/ScriptDetailsRoot';
import { Stats } from './Stats';
import { Messages } from './Messages';
import { ScrollArea } from '@/components/ui/scroll-area';

export function Activity() {
  return (
    <TabsContent value={ScriptDetailsTab.Activity} className="flex flex-col gap-6 pt-6 overflow-hidden">
      <ScrollArea>
        <Stats />
        <Messages />
      </ScrollArea>
    </TabsContent>
  );
}
