import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { setAuthToken, useCurrentAddress } from './apollo';
import { Button } from './components/ui/button';
import { shortenAddress } from './utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Popover, PopoverContent, PopoverTrigger } from './components/ui/popover';
import { MetaMaskIcon } from '@ui-kit/Icons';
import { CoinsIcon, UploadCloudIcon, UserIcon } from 'lucide-react';

function ComputeUnits() {
  // TODO: get from source
  const data = '∞';

  return (
    <div className="flex flex-col items-end">
      <Button variant="ghost" className="gap-2 flex items-center p-0 h-fit">
        <CoinsIcon className="w-4 h-4" />
        <span className="font-medium">Credit</span>
      </Button>
      <span className="text-sm leading-4 w-fit">{data} CU</span>
    </div>
  );
}

function Storage() {
  // TODO: get from source
  const storage = 120;
  const total = 200;

  return (
    <div className="flex flex-col items-end">
      <Button variant="ghost" className="gap-2 flex items-center p-0 h-fit">
        <UploadCloudIcon className="w-4 h-4" />
        <span className="font-medium">Storage</span>
      </Button>
      <span className="text-sm leading-4 w-fit">{`${storage}Go / ${total}Go`}</span>
    </div>
  );
}

function UserMenu() {
  const currentAddress = useCurrentAddress();

  return (
    <Popover>
      <PopoverTrigger asChild className="self-center">
        <Button variant="secondary" className="w-9 h-9 !p-0">
          <UserIcon className="w-4 h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-4 px-6 py-4 -translate-x-3 translate-y-1">
        <div>
          <div className="pt-2.5 text-base leading-7 font-bold">{shortenAddress(currentAddress)}</div>
          <div className="flex items-center gap-2.5">
            <MetaMaskIcon className="h-4 w-4" />
            <span className="text-sm text-muted-foreground">Web3 wallet</span>
          </div>
        </div>
        <Button onClick={() => setAuthToken(null)}>Logout</Button>
      </PopoverContent>
    </Popover>
  );
}

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBackClick = () => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    navigate(location.pathname.substring(0, lastSlashIndex));
  };

  return (
    <Button className="flex items-center gap-2" variant="secondary" onClick={handleGoBackClick}>
      <ArrowLeftIcon className="w-4 h-4" />
      <span className="text-base">Back</span>
    </Button>
  );
}

export function Header() {

  return (
    <div className="flex items-center gap-2.5 pb-4 border-b h-[52px]">
      <BackButton />
      <div className="flex-1 justify-end flex gap-4 h-full">
        <div className="flex gap-4 items-center h-full">
          <ComputeUnits />
          {/* hidden for now */}
          {/* <Separator orientation="vertical" />
          <Storage /> */}
        </div>
        <UserMenu />
      </div>
    </div>
  );
}
