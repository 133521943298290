import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

type PayloadTitleProps = {
  title: ReactNode;
  actions: ReactNode;
  className?: string;
};

export function PayloadTitle({ title, actions, className }: PayloadTitleProps) {
  return (
    <div className={cn('flex items-center gap-2 w-full h-[52px] p-2.5 pl-4', className)}>
      <div>{title}</div>
      <div className="flex-1 flex items-center gap-2 justify-end">{actions}</div>
    </div>
  );
}
