import { useEffect, useMemo, useState } from 'react';
import { Loader, isHexString, localStorageEffect } from '@utils';
import { gql, useApolloClient } from '@apollo/client';
import { RunAndDispatch, RunAndDispatch_executeAndDispatch, TestScript, TestScriptInput, TestScript_testScript } from '@gql';
import { atom, useRecoilState } from 'recoil';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ScriptState } from '../useScript';
import { JsonBlock } from '@ui-kit/JsonBlock';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { FormField } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { networkList } from '@/constants/networks';
import { ErrorBlock } from '@ui-kit/ErrorBlock';
import { TestTube2Icon } from 'lucide-react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from 'sonner';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';

const blockTestAtom = atom<string>({
  key: 'blockTest',
  default: '',
  effects_UNSTABLE: [localStorageEffect('blockTest')],
});

const networkTestAtom = atom<string>({
  key: 'networkTest',
  default: 'ethereum',
  effects_UNSTABLE: [localStorageEffect('networkTest')],
});

function getErrorMessage(message: string) {
  if (message.startsWith('script.ts (')) {
    return 'Your script has Typescript errors, please fix them and retry';
  }
  if (message.includes('does not exist, or is too old')) {
    return 'This block does not exist or is too old. Simulations only work for recent blocks yet.';
  }
  return message;

}


export type RunEmit = {
  type: 'emit';
  data: any;
};
export type RunError = { type: 'error'; error: ErrorData };

export type RunConsole = { type: 'console'; level: string; args: any[] }

export type RunEvent = RunError | RunEmit | RunConsole;

export interface ErrorData {
  message: string;
  stack: string | nil;
}


export function ScriptTester({ script: { draft, scriptId } }: { script: ScriptState }) {
  const { state } = useLocation();
  const [block, setBlock] = useRecoilState(blockTestAtom);
  const isValidBlock = block && (isHexString(block.trim()) || /^\d+$/.test(block.trim()));
  const [network, setNetwork] = useRecoilState(networkTestAtom);
  const [testParams, setTestParams] = useState<TestScriptInput | null>(null);
  const [executeParams, setExecuteParams] = useState<TestScriptInput | null>(null);
  const [cnt, setcnt] = useState(0);
  const [cntExec, setcntExec] = useState(0);

  useEffect(() => {
    if (state && state?.data) {
      setBlock(state.data.blockNumber);
      setNetwork(state.data.network);

      setcnt(x => x + 1);
      setTestParams({
        block: state.data.blockNumber,
        ts: draft.code,
        network: state.data.network,
        filter: draft.filter as any,
      });
    }
  }, [state]);

  const test = Loader.useWrap([testParams, cnt] as const)
    .map(([t]) => (t ? t : Loader.skipped))
    .query<TestScript>([cnt],
      gql`
        query TestScript($input: TestScriptInput!) {
          testScript(input: $input) {
            events
            state
          }
        }
      `,
      v => ({
        input: v,
      }),
    )
    .map(x => x.testScript);

  const execute = Loader.useWrap([executeParams, cntExec] as const)
    .map(([t]) => (t ? t : Loader.skipped))
    .query<RunAndDispatch>([cntExec],
      gql`
        mutation RunAndDispatch($input: TestScriptInput!, $script: ScriptId!) {
          executeAndDispatch(input: $input, scriptId: $script) {events}
        }
      `,
      v => ({
        input: v,
        script: scriptId,
      }),
    )
    .map(x => x.executeAndDispatch);

  const handleRunDispatchConfirm = () => {
    setTestParams(null);
    setExecuteParams({
      block: block.trim(),
      ts: draft.code,
      network,
      filter: draft.filter as any,
    });
    setcntExec(x => x + 1);
  }


  return (
    <>
      <Card className="bg-background border-none">
        <CardContent className="px-0 py-6 gap-4 flex flex-col">
          <FormField label="Network">
            <Select onValueChange={setNetwork} value={network}>
              <SelectTrigger className="h-10 bg-muted border-none rounded-md capitalize cursor-pointer">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {networkList.map(network => (
                  <SelectItem key={network} value={network}>
                    {network}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormField>
          <FormField label="Block">
            <Input id="block" value={block} onChange={v => setBlock(v.target.value)} className="bg-muted h-10" />
            {/* TODO: not yet in design */}
            {/* <div className="absolute top-1.5 right-2">{isValidBlock ? '✅' : block && '❌'}</div> */}
          </FormField>
        </CardContent>
        <CardFooter className="p-0 pb-6">
          <div className='w-full flex gap-2'>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                {/* TODO: add loading state */}
                <Button
                  disabled={!isValidBlock}
                  variant="secondary"
                  className='w-1/2'
                >
                  Run & dispatch
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Warning !</AlertDialogTitle>
                  <AlertDialogDescription>
                  This will run the script you have on screen on the given block, and dispatch the resulting emits on all webhooks and connected websockets.
                  <br />
                  <br />
                  Would you like to proceed ?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleRunDispatchConfirm}>Confirm</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>

            <Button
              disabled={!isValidBlock}
              variant="secondary"
              className='w-1/2'
              onClick={() => {
                setcnt(x => x + 1);
                setExecuteParams(null);
                setTestParams({
                  block: block.trim(),
                  ts: draft.code,
                  network,
                  filter: draft.filter as any,
                });
              }}
            >
              Simulate
            </Button>
          </div>
        </CardFooter>
      </Card>
      {
        test.match
          .skipped(() => null)
          .loading(() =>
            <Skeleton className="h-20 w-full" />
          )
          .error((e) => <ErrorBlock title={e.name} error={getErrorMessage(e.message)} />)
          .ok(x => (
            <TestResult network={network} blockHash={block.trim()} scriptId={scriptId} result={x} />
          ))}
      {
        execute.match
          .skipped(() => null)
          .loading(() =>
            <Skeleton className="h-20 w-full" />
          )
          .error((e) => <ErrorBlock title={e.name} error={getErrorMessage(e.message)} />)
          .ok(x => (
            <TestResult network={network} blockHash={block.trim()} scriptId={scriptId} result={x} />
          ))}
    </>
  );
}

interface TestArgs {
  network: string;
  blockHash: string;
  scriptId: ScriptId | nil;
}

function TestResult({ result, ...testArgs }: { result: TestScript_testScript | RunAndDispatch_executeAndDispatch | nil } & TestArgs) {
  if (!result) {
    return <JsonBlock title="Filtered out" json={['No transaction is this block has matched your filter']} />;
  }

  return <ExecResult result={result} {...testArgs} />;
}

function ExecResult({ result, scriptId, ...testArgs }: { result: TestScript_testScript | RunAndDispatch_executeAndDispatch } & TestArgs) {
  const apollo = useApolloClient();
  const [newTestName, setNewTestName] = useState('');
  const [isTestNameModalOpen, setIsTestNameModalOpen] = useState(false);

  const makeTest = async () => {
    const sid = scriptId;
    if (!sid) {
      return;
    }
    toast.error('Coming soon');
    // toast.promise(
    //   apollo.mutate({
    //     mutation: gqlx`
    //       mutation MakeTest($input: MakeTestCaseInput!) {
    //         newTestCase(input: $input)
    //       }
    //     `,
    //     variables: {
    //       input: {
    //         ...testArgs,
    //         scriptId: sid,
    //         name: newTestName,
    //         state: result.state,
    //       },
    //     } satisfies MakeTestVariables,
    //   }),
    //   {
    //     loading: 'Creating test case...',
    //     success: 'Test case created',
    //     error: e => {
    //       console.error('failed to create test case', e);
    //       return `Failed to create test case`;
    //     },
    //   },
    // );
    setNewTestName('');
    setIsTestNameModalOpen(false);
  };

  const state = 'state' in result ? result.state as any : undefined;
  const stateChanges = useMemo(() => {
    const ch: any = [];
    // delete sections if empty
    for (const [k, v] of Object.entries(state?.rpc ?? {})) {
      ch.push({
        $log$: {
          level: 'info',
          message: ['Solidity call', k, v],
        },
      });
    }
    for (const [k, v] of Object.entries(state?.db ?? {})) {
      ch.push({
        $log$: {
          level: 'info',
          message: [`DB ${k} 👉`, v],
        },
      });
    }
    for (const [k, v] of Object.entries(state?.db?.lists ?? {})) {
      ch.push({
        $log$: {
          level: 'info',
          message: [`List ${k} 👉`, v],
        },
      });
    }

    if (!ch.length) {
      ch.push({
        $log$: {
          level: 'info',
          message: ['Your script did not interact with the state'],
        },
      });
    }
    return ch;
  }, [state]);

  const messages = useMemo(() => {
    const ret: any = ([...result.events] as any as RunEvent[])
      .map(x => {
        if (x.type === 'console') {
          return { $log$: { level: x.level, message: x.args } };
        }
        if (x.type === 'error') {
          return {
            $log$: {
              level: 'error',
              message: ['❌ execution error: ', x.error.message + '\n' + x.error.stack],
            },
          };
        }
        return x.data;
      })
    if (!ret.length) {
      ret.push({
        $log$: {
          level: 'info',
          message: ['Your script did not emit any message'],
        },
      });
    }
    return ret;
  }, [result.events]);

  return (
    <div className="flex flex-col gap-2">
      <JsonBlock
        title="Emitted messages"
        json={messages}
        actions={
          <Dialog open={isTestNameModalOpen} onOpenChange={setIsTestNameModalOpen}>
            <DialogTrigger asChild>
              <Button size="icon" variant="secondary">
                <TestTube2Icon className="w-4 h-4" />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Test name</DialogTitle>
                <DialogDescription>
                  Enter test name
                </DialogDescription>
              </DialogHeader>
              <div className="flex">
                <Input
                  className="h-10 bg-secondary border-none rounded-xl"
                  value={newTestName}
                  onChange={e => setNewTestName(e.target.value)}
                  placeholder="Test name"
                />
              </div>
              <DialogFooter>
                <Button disabled={!newTestName} type="submit" onClick={makeTest}>Make test</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        }
      />
      <JsonBlock title="State interactions" json={stateChanges} />
    </div>
  );
}
