import { gql } from '@apollo/client';
import { LoadScript, LoadScriptVariables, LoadScript_script } from '@gql';
import { Loader } from '@utils';
import { useParams } from 'react-router';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { ScriptEditor } from './ScriptEditor';
import { TabsContent } from '@/components/ui/tabs';
import { ScriptDetailsTab } from './ScriptDetailsRoot';
import { useScript } from '../useScript';
import { useState } from 'react';
import { SideContent } from '../SideContent';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { Separator } from '@/components/ui/separator';
import { ErrorAlert } from '@/components/ui/alert';

export function ScriptDetailsCode() {
  // get "scriptId" from route params
  const { scriptId } = useParams<{ scriptId: ScriptId }>();

  const script = Loader.query<LoadScript>(
    gql`
      query LoadScript($id: ScriptId!) {
        script(id: $id) {
          id
          name
          draft {
            code
            filter
          }
          deployed {
            code
            filter
          }
          webhooks {
            url
          }
        }
      }
    `,
    {
      variables: {
        id: scriptId!,
      } satisfies LoadScriptVariables,
    },
  ).map(x => x.script);

  return (
    <TabsContent value={ScriptDetailsTab.Code} className="mt-0 pt-4 flex-1 mb-4 overflow-clip">
      {script.match
        .loadingOrSkipped(() => 
          <div className="flex gap-2 h-full">
            <div className="w-2/3 h-full flex flex-col gap-2">
              <div className='flex justify-between items-center'>
                <Skeleton className="h-10 w-1/3" />
                <Skeleton className="h-8 w-20" />
              </div>
              <Skeleton className="h-full" />
            </div>
            <Separator orientation='vertical' className='w-[7px]' />
            <div className="w-1/3 h-full flex flex-col gap-4">
              <Skeleton className="h-10" />
              <Skeleton className="h-10" />
              <Skeleton className="h-10" />
            </div>
          </div>
        )
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(_script => (
          <LoadedScriptDetailsCode initial={_script} />
        ))}
    </TabsContent>
  );
}

function LoadedScriptDetailsCode({ initial }: { initial: LoadScript_script }) {
  const script = useScript(initial);
  const [draft, setDraft] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <ResizablePanelGroup direction="horizontal" className={cn('gap-4 !h-[calc(100svh-16px-52px-131px-16px-16px)]', isFullscreen && 'fixed bg-background w-screen h-screen top-0 left-0 z-50 px-2')}>
      <ResizablePanel defaultSize={(2 / 3) * 100}>
        <ScriptEditor isFullscreen={isFullscreen} script={script} isDraftChanged={setDraft} onFullScreenClick={() => setIsFullscreen(prev => !prev)} />
      </ResizablePanel>
      <ResizableHandle className="w-[7px]" withHandle />
      <ResizablePanel defaultSize={(1 / 3) * 100}>
        <SideContent isDraft={draft} script={script} />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
