import { DeployDraft, LoadScript_script, SaveDraftInput, SaveScript } from '@gql';
import { Filter, isExpensiveFilter as _isExpensiveFilter } from './filter/filterdef';
import { useCallback, useMemo, useState } from 'react';
import { deepEqual, fetchApolloAsync } from '@utils';
import { gql, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router';
import { ScriptDetailsTab } from './editor/ScriptDetailsRoot';
import { toast } from 'sonner';

type Script = {
  code: string;
  filter: Filter;
};

export interface ScriptState {
  draft: Script;
  deployed: Script | nil;
  isNew: boolean;
  scriptId: ScriptId | null;
  setCode: (code: string) => void;
  setFilter: (filter: Filter) => void;
  hasChanged: boolean;
  hasDraft: boolean;
  isDeployed: boolean;
  onSave: (name?: string) => Promise<boolean | undefined>;
  onDeploy: () => Promise<void>;
  startEdit: () => void;
  isExpensiveFilter?: boolean;
}

function empty(): Script {
  return {
    code: '',
    filter: [],
  };
}

export function useScript(_initial?: LoadScript_script | nil): ScriptState {
  const [scriptId, setScriptId] = useState<ScriptId | nil>(_initial?.id);
  const [forceEdit, setForceEdit] = useState(false);
  // "deployed" is what is currently running as our prod script
  const [deployed, setDeployed] = useState<Script | nil>(
    _initial
      ? {
          code: _initial.deployed.code,
          filter: _initial.deployed.filter as unknown as Filter,
        }
      : null,
  );
  const initValue = {
    code: _initial?.draft?.code ?? _initial?.deployed.code ?? '',
    filter: ((_initial?.draft?.filter ?? _initial?.deployed.filter ?? [{}]) as unknown as Filter).filter(
      x => Object.keys(x).length > 0,
    ),
  } satisfies Script;
  // "saved" is what we have as a draft in our db
  const [saved, setSaved] = useState<Script>(initValue);
  // value is our current edit model
  const [value, setValue] = useState<Script>(initValue);

  const isExpensiveFilter = useMemo(() => _isExpensiveFilter(value.filter), [value.filter]);

  const apollo = useApolloClient();
  const setCode = (code: string) => setValue({ ...(value ?? deployed ?? empty()), code });
  const setFilter = (filter: Filter) => {
    setValue({ ...(value ?? deployed ?? empty()), filter })
  };
  const nav = useNavigate();

  // check if is deployed
  const isDeployed = useMemo(() => {
    const ret = saved.code === deployed?.code && deepEqual(saved.filter, deployed?.filter);
    return ret;
  }, [deployed, saved?.code, saved?.filter]);

  // check if has changed
  const hasChanged = useMemo(() => {
    return value.code !== saved.code || !deepEqual(value.filter, saved.filter);
  }, [saved, value?.code, value?.filter]);

  // check if has draft
  const hasDraft = useMemo(() => {
    return forceEdit || hasChanged || saved.code !== deployed?.code || !deepEqual(saved.filter, deployed?.filter);
  }, [hasChanged, deployed, saved?.code, saved?.filter, forceEdit]);

  const onSave = useCallback(
    async (name?: string) => {
      const toastId = toast.loading('Saving...');

      try {
        const { saveDraft: id } = await fetchApolloAsync<SaveScript>(
          apollo,
          gql`
            mutation SaveScript($id: ScriptId, $input: SaveDraftInput!) {
              saveDraft(id: $id, draft: $input)
            }
          `,
          {
            id: scriptId,
            input: {
              ts: value.code,
              filter: value.filter as any,
            } satisfies SaveDraftInput,
          },
        );
        if (!scriptId && name) {
          const isNamedSaved = await apollo.mutate({
            mutation: gql`
              mutation SetScriptName($id: ScriptId!, $name: String!) {
                saveScriptOptions(id: $id, options: { name: $name })
              }
            `,
            variables: {
              id,
              name,
            },
          });
          if (isNamedSaved) {
            nav(`/scripts/${id}?tab=${ScriptDetailsTab.Code}`);
          }
        }
        setScriptId(id);
        setSaved({ ...value });
        toast.success('Script saved !', { id: toastId });
        return true;
      } catch (e: any) {
        // catch TS compilation error
        if (e?.message?.startsWith('script.ts (')) {
          toast.error('Failed to save: Your script has a syntax error.', { id: toastId });
          return false;
        }
        // catch other errors
        console.error('failed to save script', e);
        toast.error(e.message, { id: toastId });
        return false;
      }
    },
    [value, scriptId],
  );

  const onDeploy = useCallback(async () => {
    const toastId = toast.loading('Deploying...');

    try {
      if (!scriptId) {
        throw new Error('Script not saved');
      }
      await fetchApolloAsync<DeployDraft>(
        apollo,
        gql`
          mutation DeployDraft($id: ScriptId!) {
            deployDraft(id: $id)
          }
        `,
        {
          id: scriptId,
        },
      );
      setDeployed({ ...value });
      toast.success('Script deployed !', { id: toastId });
    } catch (e: any) {
      // catch TS compilation error
      if (e?.message?.startsWith('script.ts (')) {
        toast.error('Failed to deploy: Your script has a syntax error.', { id: toastId });
        console.error('Failed to deploy: Your script has a syntax error.')
      }
      // catch other errors
      console.error('Failed to deploy script', e);
      toast.error('Error deploying script', { id: toastId });
    }
  }, [value, scriptId]);

  // wrap in a useMemo() to avoid too many re-renders (this whole object is passed around quite often)
  return useMemo<ScriptState>(
    () => ({
      scriptId: scriptId ?? null,
      startEdit: () => setForceEdit(true),
      isNew: !scriptId,
      draft: value,
      deployed: deployed,
      setCode,
      setFilter,
      hasChanged,
      hasDraft,
      isDeployed,
      onSave,
      onDeploy,
      isExpensiveFilter,
    }),
    // no need to have set-state functions in the deps
    [value, deployed, scriptId, hasChanged, hasDraft, isDeployed, onSave, onDeploy],
  );
}
